import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Service from "./Service";
import "./style.css";

const ServicesSlide = (props) => {
  const { services, lang, arPhrase, enPhrase } = props;

  const [oldSlide, setOldSlide] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeSlide2, setActiveSlide2] = useState(0);

  const settings = {
    className: "center",
    centerMode: true,
    focusOnSelect: true,
    dots: true,
    infinite: true,
    centerPadding: "0px",
    // centerPadding: "200px",
    // centerPadding: "440px",
    // autoplay: false,
    // pauseOnHover: true,
    // speed: 2400,
    slidesToShow: 7,
    slidesToScroll: 5,
    initialSlide: 1,
    rows: 1,
    rtl: false,
    adaptiveHeight: false,
    beforeChange: (current, next) => {
      setOldSlide(current);
      setActiveSlide(next);
    },
    afterChange: (current) => setActiveSlide2(current),
    responsive: [
      {
        breakpoint: 1640,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 5,
          infinite: true,
          // centerPadding: "362px",
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 5,
          infinite: true,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 5,
          infinite: true,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          centerPadding: "0px",
        },
      },

      {
        breakpoint: 912,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          centerPadding: "100px",
        },
      },

      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
          centerPadding: "250px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScrol: 2,
          infinite: true,
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "60px",
        },
      },

      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "150px",
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "120px",
        },
      },

      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          centerPadding: "110px",
          infinite: true,
          initialSlide: 5,
          speed: 150,
          dots: true,
        },
      },

      {
        breakpoint: 414,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "95px",
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "80px",
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "80px",
          infinite: true,
          speed: 400,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "80px",
          infinite: true,
          speed: 400,
        },
      },
    ],
  };

  const openPDF = () => {
    // Replace 'sample.pdf' with the actual path to your PDF file
    window.open("/assets/img/Application Fees List.pdf", "_blank");
  };

  return (
    <>
      <Slider {...settings}>
        {services
          ?.slice(0)
          .reverse()
          .map((slideItem, indx) => (
            <Service
              key={indx}
              slideItem={slideItem}
              indx={indx}
              lang={lang}
              arPhrase={arPhrase}
              enPhrase={enPhrase}
              activeSlide={activeSlide}
              setActiveSlide={setActiveSlide}
              setOldSlide={setOldSlide}
              setActiveSlide2={setActiveSlide2}
              activeSlide2={activeSlide2}
              oldSlide={oldSlide}
            />
          ))}
      </Slider>

      <div
        className={`flex items-center justify-center ${
          lang === "en" ? "mt-[55px]" : "mt-[55px]"
        }`}
      >
        <button
          onClick={openPDF}
          className="flex flex-row items-center gap-[8px] bg-background-home  h-[44px] font-bold font-Almarai text-[14px]  py-3 px-3 rounded-[8px]  text-[#815327]"
        >
          <img
            src="/assets/img/documentrequesthome.svg"
            alt="archive"
            className="w-5 h-5"
          />
          <span className="text-[#fff] font-Almarai text-[14px]">
            {lang === "en" ? "Application Fee List" : "قائمة رسوم الطلبات"}
          </span>
        </button>
      </div>
    </>
  );
};

export default ServicesSlide;
