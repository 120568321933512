// import { useRouter } from "next/router";
import React, { createContext, useEffect, useState } from "react";
// import ListPhrases from "@/network/api/phrases";

import { getRequester } from "../services/Common";

const RequesterContext = createContext([{}, () => {}]);

const RequesterProvider = ({ children }) => {
  const [requesterInfo, setRequesterInfo] = useState([]);
  const [phrasesLoading, setPhrasesLoading] = useState(true);
  const [lang, setLang] = useState("");

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");

    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }
    fetchListPhrasesFromAPI(currentLang);
  }, []);

  const fetchListPhrasesFromAPI = (currentLang) => {
    const token = window.localStorage.getItem("access_token");
    setPhrasesLoading(true);
    if (token) {
      getRequester(currentLang)
        .then((res) => {
          setRequesterInfo(res);
        })
        .catch((err) => {});
    }
    setPhrasesLoading(false);
  };

  return (
    <RequesterContext.Provider value={[requesterInfo, phrasesLoading]}>
      {!phrasesLoading && children}
    </RequesterContext.Provider>
  );
};

export { RequesterContext, RequesterProvider };
