import React, { useContext, useEffect, useState } from "react";
import { Container } from "../components/utils";
import { PhrasesContext } from "../context/phrasesContext";
import { getPrivacyPolicy } from "../services/Common";
import ThemeLayout from "../layouts/ThemeLayout";
const PrivacyPolicy = () => {
  const [lang, setLang] = useState("");
  const [privacy, setPrivacy] = useState("");

  const [enPhrase, arPhrase] = useContext(PhrasesContext);
console.log("lang",lang)
  // const getPrivacyPolices = (lang) => {
  //   console.log("Language sent to API:", lang);

  //   return getPrivacyPolicy(lang).then((res) => {
  //     if (!res || res.length === 0) {
  //       console.error("Empty or invalid response received");
  //     } else {
  //       console.log("API Response Data:", res);
  //       setPrivacy(res);
  //     }
  //   });
  // };

  const getPrivacyPolices = (currentLang) => {
    return getPrivacyPolicy(currentLang).then((res) => {
      setPrivacy(res);
    });
  };

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }
    getPrivacyPolices(currentLang);
  }, []);

 

  const sanitizedHtml = privacy.replace(/className/g, "class");

  return (
    <ThemeLayout>
      <Container>
        {/* <div className="flex flex-col items-center justify-center pb-16 text-justify">
            <div className="flex flex-col items-center justify-center my-5">
              <h3 className="text-3xl lg:text-4xl text-black leading-snug font-semibold">
                {lang === "en"
                  ? enPhrase["lbl_privacy_policy"]
                  : arPhrase["lbl_privacy_policy"]}
              </h3>
            </div>
            <div className="items-center w-full ">
              {lang === "en" && (
                <div className="w-full p-10 terms">
                  <div>
                    
                    <h4 className="!text-[1.75rem] !font-bold">Applicable laws</h4>
                    <p className="!text-[1rem] !leading-5">
                      The laws of the United Arab Emirates severally shall be
                      applicable in all matters relating to disputes that may
                      arise from the use of this website, and the courts of the
                      United Arab Emirates shall have exclusive jurisdiction to
                      consider and decide such disputes.
                    </p>
                    <h4 className="!text-[1.75rem] !font-bold">Privacy Policy Amendment </h4>
                    <p className="!text-[1rem] !leading-5">
                      Emirates Judgment Enforcement reserves the right to make
                      amendments to the terms of the Privacy Policy without the
                      need to provide notice, Your continued use of our website
                      constitutes acceptance of such amendments.
                    </p>

                    <h4 className="!text-[1.75rem] !font-bold">Severability of conditions </h4>
                    <p className="!text-[1rem] !leading-5">
                      The cancellation of one or more provisions of these Terms
                      - due to their illegality or unenforceability - shall not
                      affect the validity of the remaining provisions as being
                      in full force and effect.
                    </p>

                    <h4 className="!text-[1.75rem] !font-bold">
                      Protection of personal information
                    </h4>
                    <p className="!text-[1rem] !leading-5">
                      Your personal information will only be available to
                      Emirates Judgment Enforcement personnel who need to know
                      that information, Your personal information will not be
                      made available to the public without your consent.
                      Moreover, the personal information of website users will
                      not be exchanged, sold, or transferred to any party
                      without your prior consent. Access to such information is
                      only available to those professionals who provide Emirates
                      Judgement Enforcement services consistent with the nature
                      of your dealings with our site.
                    </p>

                    <p className="!text-[1rem] !leading-5">
                      If you suspect that your personal information stored or
                      circulated through our website has been violated or
                      accessed illegally or in violation of our security and
                      privacy policy, please contact us immediately and we will
                      take the necessary measures to verify the protection of
                      your data in accordance with the highest protection
                      standards followed in accordance with international
                      standards.
                    </p>
                  </div>
                </div>
              )}

              {lang === "ar" && (
                <div className="w-full p-10 terms">
                  <div>
                   
                  <h4 className="!text-[1.75rem] !font-bold">القوانين السارية</h4>
                    <p className="!text-[1rem] !leading-6">
                      تكون قوانين دولة الإمارات العربية المتحدة وحدها هي
                      القوانين واجبة التطبيق في كل ما يتعلق بالنزاعات التي قد
                      تنشأ من جراء إستخدام هذا الموقع الإلكتروني، كما تختص محاكم
                      دولة الإمارات العربية المتحدة حصرياً بالنظر في تلك
                      النِزاعات والبت فيها.
                    </p>
                    <h4 className="!text-[1.75rem] !font-bold">تعديل سياسة الخصوصية </h4>
                    <p className="!text-[1rem] !leading-6">
                      تحتفظ الإمارات لتنفيذ الأحكام بالحق في إجراء أي تعديل
                      لشروط سياسة الخصوصية دون الحاجة إلى تقديم إخطار، ويعد
                      استمرارك في استخدام موقعنا قبولاً لهذه التغييرات.
                    </p>

                    <h4 className="!text-[1.75rem] !font-bold">قابلية الشروط للتجزئة</h4>
                    <p className="!text-[1rem] !leading-6">
                      تحتفظ الإمارات لتنفيذ الأحكام بالحق في إجراء أي تعديل
                      لشروط سياسة الخصوصية دون الحاجة إلى تقديم إخطار، ويعد
                      استمرارك في استخدام موقعنا قبولاً لهذه التغييرات.
                    </p>

                    <h4 className="!text-[1.75rem] !font-bold">حماية المعلومات الشخصية</h4>
                    <p className="!text-[1rem] !leading-6">
                      ستكون معلوماتك الشخصية متاحة فقط لموظفي الإمارات لتنفيذ
                      الأحكام الذين يحتاجون إلى معرفة تلك المعلومات، ولن تكون
                      معلوماتك الشخصية متاحة للاطلاع العام من دون موافقتك، كما
                      أن المعلومات الشخصية لمستخدمي الموقع لن يتم تبادلها أو
                      بيعها أو نقلها إلى أي طرف من دون موافقتك المسبقة. إن
                      الوصول إلى تلك المعلومات غير متاح إلا لأولئك المُختصين
                      الذين يتولون توفير خدمات الإمارات لتنفيذ الأحكام المتوافقة
                      مع طبيعة تعاملك مع موقعنا.
                    </p>
                    <p className="!text-[1rem] !leading-6">
                      إذا ساورك الشَّك في أن معلوماتك الشخصية المُخزنة أو
                      المُتداولة من خلال موقعنا قد تم إنتهاكها أو الوصول إليها
                      بطريق غير مشروع أو مُخالف لسياسة الأمن والخصوصية التي
                      ننتهجها، يرجى التواصل معنا على الفور، وسنقوم بإتخاذ
                      الإجراءات اللازمة للتحقق من حماية بياناتك وفق أعلى معايير
                      الحماية المُتبعة وفقاً للمعايير العالمية.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div> */}

      
         <div className="flex flex-col items-center justify-center my-5">
              <h3 className="text-2xl lg:text-3xl text-gray-600 leading-snug font-semibold">
                {lang === "en"
                  ? enPhrase["lbl_privacy_policy"]
                  : arPhrase["lbl_privacy_policy"]}
              </h3>
            </div>
        <div className="text-gray-600 leading-relaxed" dangerouslySetInnerHTML={{ __html: sanitizedHtml }}></div>
      </Container>
    </ThemeLayout>
  );
};

export default PrivacyPolicy;
