import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const OurPartner = ({ partners, lang, enPhrase, arPhrase }) => {
  const settings = {
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 6,
    slidesToScroll: 3,
    focusOnSelect: false,
    speed: 350,
    autoplay: true,
    rtl: true,
    dots: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 820,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          autoplay: false,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 5,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 2.7,
          slidesToScroll: 2,
          dots: true,
          autoplay: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 2,
          dots: true,
          autoplay: false,
        },
      },
      {
        breakpoint: 414,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2,
          dots: true,
          autoplay: false,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2,
          dots: true,
          autoplay: false,
        },
      },

      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1.7,
          slidesToScroll: 2,
          dots: true,
          autoplay: false,
        },
      },
    ],
  };

  return (
    <div
      id="partner"
      className="mt-[0px] xl:mt-[20px] mb-[80px] md:mb-[160px] flex flex-col items-center justify-center cursor-pointer"
    >
      <section className="flex items-center gap-[24px] h-[41px] md:h-[56px] mb-[40px] md:mb-[48px]">
        <img
          src="/assets/img/home/partnersIcon.png"
          alt="Partners"
          className="w-[30px] h-[39.5px] lg:w-[56px] lg:h-[56px]"
        />
        <h1 className="text-[26px] lg:text-[32px] text-[#020202] font-bold">
          {" "}
          {lang === "en"
            ? enPhrase["lblSuccessPartners"]
            : arPhrase["lblSuccessPartners"]}{" "}
        </h1>
      </section>
      <div className="w-full px-[25px] ">
        <Slider {...settings}>
          {partners.map((item,index) => (
            <div key={index} className="!w-[150px] h-[136px] lg:!w-[200px] lg:h-[145px] rounded-2xl border border-[#e6d7a2] border-solid px-[4px] py-[16px] bg-[#f9f7ed] mb-[30px] lg:mb-[45px]">
              <div className="flex flex-col items-center">
                <p className="text-center text-[12px] lg:text-[16px] text-[#020202] font-bold h-[63px] lg:h-[48px]">
                  {item?.name}
                </p>
                <div className="w-[142px] h-[44px] lg:w-[192px] lg:h-[44px] bg-[#e6d7a2] rounded-b-xl mt-[7px] lg:mt-[30px] flex items-center justify-center">
                  <p className="text-center text-[12px] text-[#020202 ] font-bold  m-auto">
                    {item?.emiratName}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default OurPartner;

// // Swiper
// import React from "react";
// // Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";

// import "./style.css";

// import required modules
// import { Pagination } from "swiper/modules";

// export default function OurPartner({ partners, lang, enPhrase, arPhrase }) {
//   return (
//     <>
//       <div
//         id="partner"
//         className="mt-[0px] xl:mt-[20px] mb-[80px] md:mb-[160px] flex flex-col items-center justify-center px-[24px] lg:px-0"
//       >
//         <section className="flex items-center gap-[24px] h-[41px] md:h-[56px] mb-[40px] md:mb-[48px]">
//           <img
//             src="/assets/img/home/partnersIcon.png"
//             alt="Partners"
//             className="w-[30px] h-[39.5px] lg:w-[56px] lg:h-[56px]"
//           />
//           <h1 className="text-[26px] lg:text-[32px] text-[#020202] font-bold">
//             {" "}
//             {lang === "en"
//               ? enPhrase["lblSuccessPartners"]
//               : arPhrase["lblSuccessPartners"]}{" "}
//           </h1>
//         </section>
//         <div className="w-full pr-0 lg:pr-[24px]">
//           <Swiper
//             slidesPerView={8}
//             spaceBetween={30}
//             loop={true}
//             freeMode={true}
//             autoplay={true}
//             // dir="rtl"
//             breakpoints={{
//               360: {
//                 slidesPerView: 2,
//                 spaceBetween: 20,
//               },
//               375: {
//                 slidesPerView: 3,
//                 spaceBetween: 20,
//               },
//               412: {
//                 slidesPerView: 2,
//                 spaceBetween: 20,
//               },
//               640: {
//                 slidesPerView: 2,
//                 spaceBetween: 20,
//               },
//               768: {
//                 slidesPerView: 4,
//                 spaceBetween: 40,
//               },
//               1024: {
//                 slidesPerView: 5,
//                 spaceBetween: 50,
//               },
//             }}
//             className="mySwiper"
//           >
//             {partners.map((item) => (
//               <SwiperSlide className="!w-[150px] md:!w-auto">
//                 <div className="!w-[150px] h-[136px] lg:!w-[200px] lg:h-[145px] rounded-2xl border border-[#e6d7a2] border-solid px-[4px] py-[16px] bg-[#f9f7ed]">
//                   <div className="flex flex-col items-center">
//                     <p className="text-center text-[14px] lg:text-[16px] text-[#020202] font-bold h-[63px] lg:h-[48px]">
//                       {item?.name}
//                     </p>
//                     <div className="w-[142px] h-[44px] lg:w-[192px] lg:h-[44px] bg-[#e6d7a2] rounded-b-xl mt-[7px] lg:mt-[30px] flex items-center justify-center">
//                       <p className="text-center text-[12px] text-[#020202 ] font-bold  m-auto">
//                         {item?.emiratName}
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               </SwiperSlide>
//             ))}
//           </Swiper>
//         </div>
//       </div>
//     </>
//   );
// }

// import React from "react";
// // Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/free-mode";
// import "swiper/css/pagination";

// import "./style.css";

// // import required modules

// export default function OurPartner({ partners, lang, enPhrase, arPhrase }) {
//   return (
//     <>
//       <Swiper slidesPerView={6} spaceBetween={30} className="mySwiper">
//         {partners.map((item) => (
//           <SwiperSlide>
//             <div className="!w-[150px] h-[136px] lg:!w-[200px] lg:h-[145px] rounded-2xl border border-[#e6d7a2] border-solid px-[4px] py-[16px] bg-[#f9f7ed]">
//               <div className="flex flex-col items-center">
//                 <p className="text-center text-[14px] lg:text-[16px] text-[#020202] font-bold h-[63px] lg:h-[48px]">
//                   {item?.name}
//                 </p>
//                 <div className="w-[142px] h-[44px] lg:w-[192px] lg:h-[44px] bg-[#e6d7a2] rounded-b-xl mt-[7px] lg:mt-[30px] flex items-center justify-center">
//                   <p className="text-center text-[12px] text-[#020202 ] font-bold  m-auto">
//                     {item?.emiratName}
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </SwiperSlide>
//         ))}
//       </Swiper>
//     </>
//   );
// }
