import classNames from "classnames/bind";
import { React, useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import { PhrasesContext } from "../../context/phrasesContext";
import Map from "../Map";
import { Container } from "../utils";
import Location from "./Location";
import "./mui-tabs.css";
import { useLocation } from "react-router-dom";
const cx = classNames;

const GeoLocations = (props) => {
  const { lang, locations } = props;
  const [enPhrase, arPhrase] = useContext(PhrasesContext);

  const [activeTab, setActiveTab] = useState(0);
  const [activeLocation, setActiveLocation] = useState(null);
  const [initMarkers, setInitMarkers] = useState([]);
  const [mapMarkers, setMapMarkers] = useState(initMarkers);
  const [mapZoom, setMapZoom] = useState(8);

  const findCenter = (markers) => {
    const lats = markers.map((m) => m.lat);
    const lngs = markers.map((m) => m.lng);
    return {
      lat: (Math.min(...lats) + Math.max(...lats)) / 2,
      lng: (Math.min(...lngs) + Math.max(...lngs)) / 2,
    };
  };

  useEffect(() => {
    setInitMarkers(getInitMarkers());
  }, [locations]);

  const getInitMarkers = () => {
    const allMarkers = [];
    locations?.map((item) => {
      allMarkers?.push({
        lat: parseFloat(item?.gx),
        lng: parseFloat(item?.gy),
        icon: "/assets/img/map/mark.svg",
        iconW: 80,
        iconH: 80,
        id: item.emirateId,
        iconScale: 0.7,
      });
    });

    return allMarkers;
  };

  useEffect(() => {
    setMapMarkers(initMarkers);
  }, [initMarkers]);

  const openTabHandler = (id, lat, lng) => {
    setActiveTab(id);
    const newMarks = locations.filter((item) => item.emirateName === id);

    const allChangedMarkers = [];
    newMarks?.map((item) => {
      allChangedMarkers?.push({
        lat: parseFloat(item?.gx),
        lng: parseFloat(item?.gy),
        icon: "/assets/img/map/mark.svg",
        iconW: 80,
        iconH: 80,
        id: item.emirateId,
        iconScale: 0.7,
      });
    });

  
    if (id === 0) {
      setMapZoom(8);
      setActiveLocation(null);
      setMapMarkers(initMarkers);
    } else {
      setMapZoom(8);
      setActiveLocation(null);
      setMapMarkers(allChangedMarkers);
    }
  };

  

  const onTabClick = (e, index) => {
    setActiveTab(index);
  };

  const groupByCategory = locations.reduce((group, location) => {
    const { emirateName } = location;
    group[emirateName] = group[emirateName] ?? [];
    group[emirateName].push(location);
    return group;
  }, {});



  const { state } = useLocation();
  const { targetId } = state || {};

  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView();
    }
  }, [targetId]);

  // console.log("Object.values(groupByCategory)[0]",Object.values(groupByCategory))

  return (
    <div id="offices">
      {/* <div className={"mt-[80px] m-auto mb-[80px] w-full flex-col px-[24px]"}> */}
      <div className=" xl:max-w-[1320px] m-auto px-[24px] 2xl:px-0">
        {/* <Container> */}
        <div className="flex mb-[48px]">
          <h1 className="text-[44px] text-[#361e12] font-bold">
            {lang === "en"
              ? enPhrase["lbl_office_title"]
              : arPhrase["lbl_office_title"]}
          </h1>
        </div>
        <div className="flex w-full">
          <div className=" w-[42%] xl:w-[40%] 2xl:w-[34%] border-[2px] rounded-[20px] border-[#f2eccf] absolute z-[2] bg-[#fff] h-[640px]">
            <div className="flex pt-[22px] border-b-[1px] border-[#ddd] h-[63px] cursor-pointer">
              <Tabs
                activeTab={activeTab}
                onTabClick={onTabClick}
                hideNavBtnsOnMobile={false}
                leftBtnIcon={
                  <div className="bg-white rounded-[50%] border-solid border-[0.5px] border-[#888] me-[5px] mt-[-10px]">
                    <img
                      className="rotate-180	"
                      src="/assets/img/home/arrow.svg"
                      alt="arrow"
                    />
                  </div>
                }
                rightBtnIcon={
                  <div className="bg-white rounded-[50%] border-solid border-[0.5px] border-[#888] ms-[10px] mt-[-10px]">
                    <img src="/assets/img/home/arrow.svg" alt="arrow" />
                  </div>
                }
              >
                <Tab>
                  <div
                    className={cx("pl-[20px] pr-[20px]")}
                    onClick={(index, lat, lng) => openTabHandler(0, "", "")}
                  >
                    <p className="text-[16px] text-[#888] font-bold">
                      {lang === "en" ? enPhrase["lblAll"] : arPhrase["lblAll"]}
                    </p>
                  </div>
                </Tab>

                {Object.entries(groupByCategory)?.map(([category, item]) => {
                  return (
                    <Tab key={category}>
                      <div
                        key={category?.emirateId}
                        className={cx("flex pl-[20px] pr-[20px]")}
                        onClick={(index, lat, lng) =>
                          openTabHandler(category, category?.gx, category?.gy)
                        }
                      >
                        <p className="text-[15px] text-[#888] font-bold">
                          {category}
                        </p>
                      </div>
                    </Tab>
                  );
                })}
              </Tabs>
            </div>

            <div className="flex flex-col overflow-scroll overflow-x-hidden max-h-[575px]">
              {/* {locations?.map((item) => {
                return activeTab === 0 || activeTab === item?.emirateId ? (
                  <Location
                    title={item?.name}
                    des={
                      item?.address
                    }
                    setActiveLocation={setActiveLocation}
                    activeLocation={activeLocation}
                    LocationId={item?.emirateId}
                    phone={item?.phone}
                    email={item?.email}
                    lat={parseFloat(item?.gx)}
                    lng={parseFloat(item?.gy)}
                    setMapMarkers={setMapMarkers}
                    initMarkers={initMarkers}
                    setMapZoom={setMapZoom}
                    inAll={activeTab === 0}
                  />
                ) : null;
              })} */}

              {/* Updates */}

              {activeTab === 0 ? (
                <div>
                  {locations?.map((item) => (
                    <Location
                      title={item?.name}
                      des={item?.address}
                      setActiveLocation={setActiveLocation}
                      activeLocation={activeLocation}
                      LocationId={item?.name}
                      phone={item?.phone}
                      email={item?.email}
                      lat={parseFloat(item?.gx)}
                      lng={parseFloat(item?.gy)}
                      setMapMarkers={setMapMarkers}
                      initMarkers={initMarkers}
                      setMapZoom={setMapZoom}
                      inAll={activeTab === 0}
                      lang={lang}
                    />
                  ))}
                </div>
              ) : activeTab === 1 ? (
                <div>
                  {Object.values(groupByCategory)[0]?.map((item) => (
                    <Location
                      title={item?.name}
                      des={item?.address}
                      setActiveLocation={setActiveLocation}
                      activeLocation={activeLocation}
                      LocationId={item?.name}
                      phone={item?.phone}
                      email={item?.email}
                      lat={parseFloat(item?.gx)}
                      lng={parseFloat(item?.gy)}
                      setMapMarkers={setMapMarkers}
                      initMarkers={initMarkers}
                      setMapZoom={setMapZoom}
                      inAll={activeTab === 0}
                      lang={lang}
                    />
                  ))}
                </div>
              ) : activeTab === 2 ? (
                <div>
                  {Object.values(groupByCategory)[1]?.map((item) => (
                    <Location
                      title={item?.name}
                      des={item?.address}
                      setActiveLocation={setActiveLocation}
                      activeLocation={activeLocation}
                      LocationId={item?.name}
                      phone={item?.phone}
                      email={item?.email}
                      lat={parseFloat(item?.gx)}
                      lng={parseFloat(item?.gy)}
                      setMapMarkers={setMapMarkers}
                      initMarkers={initMarkers}
                      setMapZoom={setMapZoom}
                      inAll={activeTab === 0}
                      lang={lang}
                    />
                  ))}
                </div>
              ) : activeTab === 3 ? (
                <div>
                  {Object.values(groupByCategory)[2]?.map((item) => (
                    <Location
                      title={item?.name}
                      des={item?.address}
                      setActiveLocation={setActiveLocation}
                      activeLocation={activeLocation}
                      LocationId={item?.name}
                      phone={item?.phone}
                      email={item?.email}
                      lat={parseFloat(item?.gx)}
                      lng={parseFloat(item?.gy)}
                      setMapMarkers={setMapMarkers}
                      initMarkers={initMarkers}
                      setMapZoom={setMapZoom}
                      inAll={activeTab === 0}
                      lang={lang}
                    />
                  ))}
                </div>
              ) : activeTab === 4 ? (
                <div>
                  {Object.values(groupByCategory)[3]?.map((item) => (
                    <Location
                      title={item?.name}
                      des={item?.address}
                      setActiveLocation={setActiveLocation}
                      activeLocation={activeLocation}
                      LocationId={item?.name}
                      phone={item?.phone}
                      email={item?.email}
                      lat={parseFloat(item?.gx)}
                      lng={parseFloat(item?.gy)}
                      setMapMarkers={setMapMarkers}
                      initMarkers={initMarkers}
                      setMapZoom={setMapZoom}
                      inAll={activeTab === 0}
                      lang={lang}
                    />
                  ))}
                </div>
              ) : activeTab === 5 ? (
                <div>
                {Object.values(groupByCategory)[4]?.map((item) => (
                  <Location
                    title={item?.name}
                    des={item?.address}
                    setActiveLocation={setActiveLocation}
                    activeLocation={activeLocation}
                    LocationId={item?.name}
                    phone={item?.phone}
                    email={item?.email}
                    lat={parseFloat(item?.gx)}
                    lng={parseFloat(item?.gy)}
                    setMapMarkers={setMapMarkers}
                    initMarkers={initMarkers}
                    setMapZoom={setMapZoom}
                    inAll={activeTab === 0}
                    lang={lang}
                  />
                ))}
                </div>
              ):null}
            </div>
          </div>
          <div
            className={cx(
              "flex w-[60%] relative justify-end	",
              lang === "en" ? "left-[38%]" : "right-[40%]"
            )}
          >
            <Map
              mapH={"640px"}
              borderRadius={lang === "en" ? "0px 20px 20px 0px" : "20px 0px"}
              mapW={"98%"}
              centerPoint={findCenter(mapMarkers)}
              zoom={mapZoom}
              Markers={mapMarkers}
            />
          </div>
        </div>
        {/* </Container> */}
      </div>
    </div>
  );
};

export default GeoLocations;
