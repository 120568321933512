import { Disclosure } from "@headlessui/react";
import React, { useRef, useState } from "react";
import { BiMinus } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
// import Help from "../components/Help";
import Help from "../components/Help";
import { Container } from "../components/utils";
import { useAuthContext } from "../providers/authContext";
import HelpLatest from "./HelpLatest";
import "./style.css";
const FAQ = ({ faqs, lang, enPhrase, arPhrase, ticketCategories, fqa }) => {
  const { isAuthorized, HappinessHandlerClick, ContactUsHandler } =
    useAuthContext();

  const AccordionRefs = useRef([]); // store accordion buttons as Refs
  const [currentAccordion, setCurrentAccordion] = useState(0); // set the current
  function closeCurrent(e) {
    const button = e.target.closest("button"); // get the button
    const buttonParent = button.parentElement.parentElement; // get the buttons parent (<dt> tag here)
    const parent = buttonParent.parentElement; // get the buttons parent parent (Disclosure as div here)
    const index = Array.prototype.indexOf.call(parent.children, buttonParent); // find the index of the button in container
    
    for (let i = 0; i < AccordionRefs.current.length; i++) {
      // loop throug
      if (
        AccordionRefs.current[i].querySelector("button") &&
        AccordionRefs.current[i]
          .querySelector("button")
          .getAttribute("aria-expanded") === "true" &&
        currentAccordion !== index // if it is opened and not the current
      ) {
        AccordionRefs.current[i].querySelector("button").click(); // then trigger a click to close it.
      }
    }
    setCurrentAccordion(index); // and set the current
  }

  return (
    <>
      <div className='relative'>
        <section
          id='faqs'
          className='w-full overflow-hidden wow fadeInUp mt-[5rem] bg-gradient-to-b from-[#ffffff] to-[#f2eccf] pb-[140px] lg:pb-[64px]'>
          <Container>
            <div className='flex flex-col gap-[32px] lg:gap-10 items-center justify-center py-16'>
              <div className='flex flex-col items-center justify-center px-[24px] lg:px-0'>
                <h3 className='text-3xl lg:text-[44px] text-[#361e12] leading-snug font-bold text-center'>
                  {lang === 'en'
                    ? 'Frequently Asked Questions'
                    : 'أكثر الأسئلة تكرارا'}
                </h3>
                <p className='text-base text-gold-1 text-center lg:max-w-xl mt-[12px] lg:mt-4'>
                  {lang === 'en'
                    ? ''
                    : 'فيما يلي بعض الأسئلة الأكثر شيوعًا حول تنفيذ الأحكام في الإمارات العربية المتحدة.'}
                </p>
              </div>
              <div className="items-center w-full">
                <div className="w-full px-4">
                  <div className="mx-auto lg:max-w-2xl w-full mb-[48px] ">
                    {faqs.map((faq, index) => (
                      <Disclosure
                        key={index}
                        className="rounded-2xl my-[8px] bg-white p-2 items-center outline outline-1 outline-gold-4 "
                        as="div"
                      >
                        {({ open }) => (
                          <div
                            className={`focus:outline focus:outline-gold-100 focus-visible:ring focus-visible:ring-gold-100 focus-visible:ring-opacity-75`}
                            onClick={closeCurrent}
                            ref={(el) => (AccordionRefs.current[index] = el)}
                          >
                            <Disclosure.Button
                              className={`flex w-full justify-between rounded-t-lg bg-white border-gold-100 px-4 py-2 text-[14px]  font-Almarai font-bold text-[#361e12] leading-7 ${
                                lang === 'en' ? ' text-left' : ' text-right'
                              }`}>
                              <span
                                className={`${
                                  open
                                    ? "text-[#b68a35] w-[250px] md:w-full"
                                    : "text-black w-[250px] md:w-full"
                                }`}
                              >
                                {faq?.question}
                              </span>
                              <BiMinus
                                className={`${
                                  open ? 'block' : 'hidden'
                                } h-5 w-5 text-black`}
                              />
                              <FiPlus
                                className={`${
                                  open ? "hidden" : "block"
                                } !h-5 !w-5 text-black`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel
                              className={`px-4 PT-2 pb-2 text-sm rounded-b-lg bg-white text-gray-500${
                                lang === "en" ? " text-left" : " text-right"
                              }`}
                              as="dd"
                            >
                              {}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `${faq?.answer}`,
                                }}
                              ></div>
                            </Disclosure.Panel>
                          </div>
                        )}
                      </Disclosure>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>

        {window && window.innerWidth < 1024 ? (
          <Help ContactUsHandler={ContactUsHandler} />
        ) : (
          <HelpLatest
            lang={lang}
            enPhrase={enPhrase}
            arPhrase={arPhrase}
            ContactUsHandler={ContactUsHandler}
          />
        )}
      </div>
    </>
  );
};
export default FAQ;
