import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

const Service = ({
  slideItem,
  lang,
  enPhrase,
  arPhrase,
  activeSlide,
  setActiveSlide,
  setOldSlide,
  setActiveSlide2,
  oldSlide,
  activeSlide2,
  services,
  indx,
}) => {
  const [requestId, setRequestId] = useState('');
  const [activeCenter, setActiveCenter] = useState(false);
  const handlerRequest = () => {
    localStorage.setItem('requestId', slideItem.id);
  };
 

  return (
    <div
      className={`w-[152px] h-[152px]  my-[30px] rounded-[24px] backdrop-blur-[15.2px] border-[0.5px] border-solid border-black-rgba bg-background-home flex flex-col items-center p-[24px] overflow-hidden Slide`}
      // onMouseEnter={() =>{
      //   setOldSlide(activeSlide)
      //   setActiveSlide(activeSlide-1)
      //   setActiveSlide2(activeSlide+1)
      // }}
    >
      {/* <img
        src={
          slideItem.icon
            ? slideItem.icon
            : `https://static.eje.ae/icons/evaluation.svg`
        }
        alt=""
        className="w-[48px] h-[48px] mb-[16px]"
      /> */}

      {activeSlide + 2 === indx ? (
        <img
          src={slideItem?.selectedicon}
          alt=''
          className='w-[48px] h-[48px] mb-[16px]'
        />
      ) : (
        <img
          src={slideItem?.icon}
          alt=''
          className='w-[48px] h-[48px] mb-[16px]'
        />
      )}
      <div>
        <p className='w-[104px] h-[36px] font-Almarai font-bold text-[14px] text-center text-[#fff] leading-[24px]	mb-[2px] par'>
          {/* {lang === "en"
            ? enPhrase["" + slideItem.titlePhrase]
            : arPhrase["" + slideItem.titlePhrase]} */}
          {slideItem.name}
        </p>
      </div>
      <div className='mt-[9px] btn'>
        <Link
          to='/request'
          className='w-[84px] h-[32px] rounded-[8px] bg-[#b68a35]'
          onClick={handlerRequest}>
          {/* {lang === 'en' ? enPhrase['lblRequest'] : arPhrase['lblRequest']} */}
          <img
                className={`text-[13px] text-[#FFF] flex items-center justify-center !h-[12px] $`}
                src="/assets/img/home/add-large.svg"
                alt="" 
              />
        </Link>
      </div>
    </div>
  );
};

export default Service;
