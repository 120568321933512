import React, { useContext, useEffect, useState } from "react";
import { Container } from "../components/utils";
import { PhrasesContext } from "../context/phrasesContext";
import FooterLayout from "../layouts/FooterLayout";
import Nav from "../layouts/Nav";
import { getTermsAndConditions } from "../services/Common";

const TermsAndConditions = () => {
  const [lang, setLang] = useState("");
  const [terms, setTerms] = useState("");

  const [enPhrase, arPhrase] = useContext(PhrasesContext);


  const getTermsAndCondition = (currentLang) => {
    return getTermsAndConditions(currentLang).then((res) => {
      setTerms(res);
    });
  };


  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }
    getTermsAndCondition(currentLang);
  }, []);

  const changeLanguage2 = (lang) => {
    if (lang === "en") {
      document.body.dir = "rtl";
      setLang("ar");
      window.localStorage.setItem("lang", "ar");
    } else {
      document.body.dir = "ltr";
      setLang("en");
      window.localStorage.setItem("lang", "en");
    }
    window.location.reload();
  };

  const sanitizedHtml = terms.replace(/className/g, "class");
  return (
    <div className="w-full">
      <Nav
        lang={lang}
        enPhrase={enPhrase}
        arPhrase={arPhrase}
        changeLanguage2={changeLanguage2}
      />
      <section
        id="faq"
        className="w-full overflow-hidden bg-gold-10 border-t border-b wow fadeInUp "
      >
        <Container>
          {/* <div className="flex flex-col items-center justify-center pb-16 md:text-justify">
            <div className="flex flex-col items-center justify-center my-5">
              <h3 className="text-3xl lg:text-4xl text-black leading-snug font-semibold">
                {lang === "en"
                  ? enPhrase["lbl_terms_condtions"]
                  : arPhrase["lbl_terms_condtions"]}
              </h3>
            </div>
            <div className="items-center w-full ">
              {lang === "en" && (
                <div className="w-full px-10 terms">
                  <div className="bg-gray-50 text-gray-800 px-6 md:px-10">
                    <div className="max-w-5xl mx-auto">
                      <div className="p-6 rounded-lg">
                        <section>
                          <h2 className="!text-[1.5rem] md:!text-[1.5rem] md:text-xl font-semibold mb-4">
                            Terms of Use of Electronic and Smart Services
                          </h2>
                          <p className="mb-4">
                            We welcome you as a user of the electronic and smart
                            services of “Emirates Judgment Enforcement”, We
                            invite you to read these terms and conditions
                            carefully, which aim to define the principles and
                            obligations necessary for the fruitful use of
                            electronic and smart services available on
                            electronic and smart media by stating the user’s
                            obligations and terms of use.
                          </p>
                          <p className="mb-4">
                            These terms and conditions apply to Emirates
                            Judgment Enforcement website and all its sections
                            and branches.
                          </p>
                          <p className="mb-4">
                            By using our website, you acknowledge that you agree
                            to be bound by these terms and conditions, You must
                            not use the website if you do not agree to the terms
                            and conditions.
                          </p>

                          <p className="mb-4">
                            It is worth noting that Emirates Judgement
                            Enforcement reserves the right to amend, change, or
                            update the website’s terms and conditions at any
                            time it deems appropriate. These amendments become
                            effective once published on the website and sent to
                            the website clients email without further notice.
                          </p>

                          <p className="mb-4">
                            Therefore, we kindly request you to review these
                            terms and conditions regularly to monitor
                            amendments. Please read the following “Terms of Use”
                            carefully before accessing the website.
                          </p>
                        </section>
                        <section>
                          <h2 className="!text-[1.5rem] md:text-xl font-semibold mb-4">
                            Terms of Use Acceptance
                          </h2>
                          <p className="mb-4">
                            By using this website, you acknowledge and agree
                            that you have read, understood, accepted, and agreed
                            to these “Terms and Conditions”.
                          </p>

                          <p className="mb-4">
                            You also confirm that you agree to the statements
                            you make below, and If you do not agree to or comply
                            with the “Terms of Use” of the Services provided by
                            the website (as defined below) and wish to
                            discontinue using the Services, please do not
                            continue to use this website or this service.
                          </p>

                          <p className="mb-4">
                            These controls and conditions stated herein
                            constitute an Official Agreement or (“Contract”)
                            between Emirates Judgment Enforcement website and
                            the users and applicants on Emirates Judgment
                            Enforcement website to enforce the resolutions
                            issued by the court.
                          </p>
                        </section>
                        <section>
                          <h2 className="!text-[1.5rem] md:text-xl font-semibold mb-4">
                            Eligibility for services
                          </h2>
                          <p className="mb-4">
                            People and individuals who are under (18) years of
                            age, who are incompetent due to foolishness, or who
                            have been declared bankrupt by a court ruling, are
                            not permitted to use Emirates Judgment Enforcement
                            website; and are not permitted to register on the
                            website and benefit from the services provided by
                            the website.
                          </p>

                          <p className="mb-4">
                            Accordingly, you acknowledge, admit, and warrant
                            (when using Emirates Judgment Enforcement website),
                            you agree to these general terms and conditions.
                          </p>

                          <p className="mb-4">
                            You can register to create an account on Emirates
                            Judgment Enforcement website by completing the
                            registration form and sending it to Emirates
                            Judgment Enforcement website.
                          </p>

                          <p>
                            You also acknowledge and warrant that all
                            information provided in the registration form is
                            complete and accurate and represents you legally and
                            that our website is not responsible for providing
                            any wrong or incorrect information mentioned in the
                            registration form and customers are not entitled in
                            any way to hold the website legally accountable for
                            any incorrect information and the persons benefiting
                            from the website bear legal and contractual
                            responsibility if the information provided is proven
                            to be incorrect.
                          </p>

                          <p>
                            If you register to open an account on Emirates
                            Judgments Enforcement website, you must choose your
                            username and password; You will be asked for your
                            email address, and country of residence and detailed
                            address, phone number, ID number, preferred
                            language, and you may be asked to provide
                            billing/payment information such as credit card
                            billing information, your credit card type, card
                            number, expiration date, as well as the billing
                            address if you are subscribed to electronic payment
                            services.
                          </p>

                          <p>
                          You can subscribe to receive Emirates Judgment Enforcement website newsletters and view our latest news and updates. In this case, you acknowledge and agree to the following:
                          </p>

                          <ul className="list-disc list-inside mb-4 space-y-2">
                            <li className="!mb-[1rem]">
                            Keep your password confidential.
                            </li>
                            <li className="!mb-[1rem]">
                            Notify Emirates Judgment Enforcement website immediately (through social media) if your password is disclosed.
                            </li>
                            <li className="!mb-[1rem]">
                            You are responsible for any activity that takes place on Emirates Judgment Enforcement website resulting from your failure to maintain the confidentiality of your password, and you are responsible for any losses that may arise as a result of this failure.
                            </li>

                            <li className="!mb-[1rem]">
                            The user/member acknowledges his agreement to compensate Emirates Judgment Enforcement for any improper, unauthorized, or illegal use of his account, whether by him or by any other person who obtains the ability to access the account through the website or electronic application, using his username and password.
                            </li>

                            <li className="!mb-[1rem]">
                            To use the account alone and you may not transfer that account to any third party or authorize any party to use it. You will be responsible if you delegate the management of your account to any third party.
                            </li>

                            <li className="!mb-[1rem]">
                            The customer is responsible for providing the website administration with the correct information for the service required to be performed.
                            </li>

                            <li className="!mb-[1rem]">
                            he right of Emirates Judgment Enforcement, in its absolute discretion and without prior notice or giving reasons, to suspend or cancel your account or modify the account data.
                            </li>

                            <li className="!mb-[1rem]">
                            If a customer wishes to stop dealing with Emirates Judgment Enforcement website, the website will retain all the operations and activities that he carried out on Emirates Judgment Enforcement website.
                            </li>
                          </ul>
                        </section>

                        <section>
                          <h2 className="!text-[1.5rem] md:text-xl font-semibold mb-4">
                          Parties using the website
                          </h2>

                          <h4 className="!text-[1.5rem] md:text-xl font-semibold mb-4">
                          Beneficiary clients are:
                          </h4>
                          <ul className="list-disc list-inside mb-4">
                            <li className="!mb-[1rem]">
                            Lawyers: the client who represents one of the parties in the cases and is obligated to provide proof of his legal capacity on behalf of the party he represents.
                            </li>
                            <li className="!mb-[1rem]">
                            Clients: They are the customers represented by individual litigants, companies, service centers, experts, and any party directly related to the procedure, provided that they prove to Emirates Judgment Enforcement website their legal capacity on behalf of the party they represent.
                             
                            </li>
                            <li className="!mb-[1rem]">
                            Emirates Judgment Enforcement website: Here, it is considered the party concerned with enforcing the court’s resolutions.
                            </li>
                          </ul>
                        </section>

                        <section>
                          <h2 className="!text-[1.5rem] md:text-xl font-semibold mb-4">
                          General Terms and Conditions
                          </h2>
                          <ul className="list-disc list-inside mb-4">
                            <li className="!mb-[1rem]">
                            Any ambiguity in any of the regulations or misunderstanding may not be interpreted in a way that adversely affects the interests of any of the parties.
                            </li>
                            <li className="!mb-[1rem]">
                            The user/member acknowledges and agrees to the special terms and conditions related to the provision of enforcement services, which are considered complementary and an integral part of the general terms and conditions governing the relationship between the parties.
                            </li>
                            <li className="!mb-[1rem]">
                            Emirates Judgment Enforcement Company has the right to postpone the implementation of the procedure in the event of an uncontrollable technical problem on the website or electronic application until this technical failure is fixed.
                            </li>

                            <li className="!mb-[1rem]">
                            Emirates Judgment Enforcement is not legally responsible for any technical or operational failure that occurs during the implementation process, whether on the website or the application, and the user/member is not entitled to claim for any compensation from Emirates Judgment Enforcement for the loss that occurred due to a technical or operational failure during the provision of the service.
                            </li>

                            <li className="!mb-[1rem]">
                            The client acknowledges that the rules and provisions for implementing court resolutions have been announced and communicated to him by Emirates Judgment Enforcement; that he has reviewed and is sufficiently aware of them and that he has reviewed all the provisions.
                            </li>

                            <li className="!mb-[1rem]">
                            The regulations and laws included in these “Terms and Conditions” govern the relationship between all parties related to the website or electronic application, and none of its provisions can be taken as a document or material evidence used against Emirates Judgment Enforcement.
                            </li>

                            <li className="!mb-[1rem]">
                            Emirates Judgment Enforcement has the full right to determine the suspension or termination of any of the services provided by the website or electronic application, and it has the right to prevent users/members from entering the website or application and/or remove any materials published on it, and take any measures, whether electronic or legal, it deems appropriate to prevent users from entering the website without prior notice if Emirates Judgment Enforcement finds it necessary to take any of the above measures for any reason, noting that Emirates Judgment Enforcement has the right to cancel inactive or non-activated accounts when necessary.
                            </li>

                            <li className="!mb-[1rem]">
                            Emirates Judgment Enforcement has the right to change the fees for the services provided by the website temporarily or permanently without prior notice, for promotional reasons; when introducing new services, or for any other reason
                            </li>

                            <li className="!mb-[1rem]">
                            All transactions in payment of service fees on Emirates Judgment Enforcement website shall be in UAE Dirhams, and Emirates Judgment Enforcement is not responsible for any loss resulting from conversion between currencies and/or loss resulting from bank transfers between different currencies.
                            </li>

                            <li className="!mb-[1rem]">
                            Value Added Tax will be added in accordance with the Federal Tax Authority Law (FTA) No. 2017/8 issued by the Government of the United Arab Emirates at a rate of 5% on the price of providing the service (where applicable) and all fees, and the application of VAT on the final price is an obligation of the customer and not an obligation on Emirates Judgment Enforcement in accordance with the Federal Tax Authority Law.
                            </li>

                            <li className="!mb-[1rem]">
                            Emirates Judgment Enforcement shall not be responsible for any technical failure in the system, failure to complete the application process and provide the service, or any technical difficulties.
                            </li>

                            <li className="!mb-[1rem]">
                            In the event of any technical error that results in the customer being refunded the amount, the customer is obliged to return the refunded amount to Emirates Judgment Enforcement Company, In the event of non-compliance, Emirates Judgment Enforcement Company has the right to take the necessary legal action against the customer.
                            </li>
                          </ul>
                        </section>
                        <section>
                          <h2 className="!text-[1.5rem] md:text-xl font-semibold mb-4">
                          Amendments to the “Terms and Conditions”
                          </h2>
                          <p className="mb-4">
                          Emirates Judgment Enforcement Company reserves the right to amend and/or change and update the General and Specific Terms and Conditions of the website or electronic application at any time it deems appropriate, and the amendments, changes, and updates are considered effective.
                          </p>
                        </section>

                        <section>
                          <h2 className="!text-[1.5rem] md:text-xl font-semibold mb-4">
                          Harm to Emirates  Judgments Enforcement
                          </h2>
                          <ul className="list-disc list-inside mb-4">
                            <li className="!mb-[1rem]">
                            Pledge not to use hacking or piracy programs, including but not limited to (hackers - robots - spiders - scrapers... etc.) to access the website or electronic application for any reason and to abide by all provisions of laws related to information technology, which are included in Federal Decree-Law No. 34 of 2021 regarding combating rumors and cybercrimes.
                            </li>
                            <li className="!mb-[1rem]">
                            Undertaking not to engage in any activity that would negatively affect the infrastructure of the website or electronic application, prevent users of the website or application from accessing it, or limit the access process to a specific category in any way.
                            </li>
                            <li className="!mb-[1rem]">
                            mirates Judgment Enforcement has the right to review your content continuously and periodically, monitor your content, and remove it in the event of a violation of the website’s terms.
                            </li>

                            <li className="!mb-[1rem]">
                            You should undertake and commit to notify Emirates Judgment Enforcement website immediately upon discovering any illegal content or activity on Emirates Judgment Enforcement website or that violates these General Terms and Conditions of the website, by contacting Emirates Judgment Enforcement.
                            </li>
                          </ul>
                        </section>

                        <section>
                          <h2 className="!text-[1.5rem] md:text-xl font-semibold mb-4">
                          Termination
                          </h2>
                          <p className="mb-4">
                          Emirates Judgment Enforcement has the right to terminate your use of the website without prior notice if you violate or fail to comply with any of the “Terms and Conditions” set forth on the website.
                          </p>

                          <p className="mb-4">
                          If the customer corrects the error he made and complies with the “Terms and Conditions” of the website within the period determined by the website and deemed appropriate, you may continue to benefit from the services provided by the website. If this period expires without the customer correcting the errors and complying with the terms and conditions of the website during the period granted by the website to the customer, the website will freeze his membership and registration on the website and prevent the customer from accessing his account on the website and viewing its contents. In this case, the customer has no right to take any legal action against the website or claim compensation.
                          </p>
                        </section>

                        <section>
                          <h2 className="!text-[1.5rem] md:text-xl font-semibold mb-4">
                          Copyright
                          </h2>
                          <p className="mb-4">
                          The contents of this website including, but not limited to, text, graphics, images, links, files, and sounds are the property of Emirates Judgment Enforcement and are protected by copyright.
                          </p>

                          <p className="mb-4">
                          Emirates Judgment Enforcement reserves all copyrights, trademarks, patents, intellectual property rights, and other information contained in this site.
                          </p>

                          <p className="mb-4">
                          Any unauthorized use, reproduction, or printing of the information, materials, and proprietary rights contained in this website is strictly prohibited.
                          </p>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {lang === "ar" && (
                <div className="w-full px-10 terms">
                  <div className="bg-gray-50 text-gray-800 px-6 md:px-10">
                    <div className="max-w-5xl mx-auto">
                      <div className="p-6 rounded-lg">
                        <section>
                          <h2 className="!text-[1.5rem] md:!text-[1.5rem] md:text-xl font-semibold mb-4">
                            شروط استخدام الخدمات الإلكترونية والذكية
                          </h2>
                          <p className="mb-4">
                            نرحب بك كمستخدم لخدمات "الإمارات لتنفيذ الأحكام"
                            الإلكترونية والذكية، وندعوك لقراءة هذه الشروط
                            والأحكام بعناية، والتي نهدف منها تحديد المبادئ
                            والإلتزامات الضرورية للاستخدام المُثمر للخدمات
                            الإلكترونية والذكية المتاحة على الوسائط الإلكترونية
                            والذكية من خلال بيان التزامات المستخدم وشروط
                            الاستخدام.
                          </p>
                          <p className="mb-4">
                            تُطبق هذه الشروط على موقع الإمارات لتنفيذ الأحكام
                            وجميع أقسامها وفروعها.
                          </p>
                          <p className="mb-4">
                            باستخدامكم لموقعنا، أنتم تعترفون بموافقتكم على
                            الإلتزام بهذه الشروط والأحكام.
                          </p>

                          <p className="mb-4">
                            يجدر التأكيد أن الإمارات لتنفيذ الأحكام تحتفظ بحقها
                            في تعديل أو تغيير أو تحديث الشروط والأحكام الخاصة
                            بالموقع في أي وقت من الأوقات تراه مناسباً، وتصبح هذه
                            التغييرات سارية بمجرد نشرها على الموقع وإرسال هذه
                            التغيرات على البريد الإلكتروني لمُستخدمي الموقع
                            وبدون سابق إشعار آخر.
                          </p>

                          <p className="mb-4">
                            لذلك، نرجو منكم مراجعة هذه الشروط والأحكام بإنتظام
                            لمراقبة التغييرات، ويرجى قراءة شروط الإستخدام
                            التالية بعناية قبل دخولكم إلى الموقع.
                          </p>
                        </section>
                        <section>
                          <h2 className="!text-[1.5rem] md:text-xl font-semibold mb-4">
                            قبول شروط الاستخدام
                          </h2>
                          <p className="mb-4">
                            باستخدامك هذا الموقع، أنت تقر وتعترف بأنك قد قرأت
                            وأدركت وقبلت ووافقت على هذه الشروط والأحكام.
                          </p>

                          <p className="mb-4">
                            كما أنك تؤكد موافقتك كذلك على الإقرارات التي تقدمها
                            بنفسك أدناه، وإذا كنت لا توافق على شروط استخدام
                            الخدمات التي يقدمها الموقع أو عدم الإلتزام بها (على
                            النحو المحدد أدناه) وترغب في التوقف عن استخدام
                            الخدمات، يرجى عدم الإستمرار في استخدام هذا الموقع أو
                            هذه الخدمة.
                          </p>

                          <p className="mb-4">
                            تشكل هذه الضوابط والشروط المذكورة إتفاق رسمي "عقد"
                            بين موقع الإمارات لتنفيذ الأحكام، والمتعاملين،
                            ومقدمي الطلبات في موقع الإمارات لتنفيذ الأحكام بغرض
                            تنفيذ القرارات الصادرة من المحكمة.
                          </p>
                        </section>
                        <section>
                          <h2 className="!text-[1.5rem] md:text-xl font-semibold mb-4">
                            أحقية الحصول على الخدمات
                          </h2>
                          <p className="mb-4">
                            الأشخاص الذي لا يزيد عمرهم عن (18) عام والمحجور
                            عليهم لسفه والمحكوم عليهم بالإفلاس بموجب حكم قضائي
                            لا يجوز لهم استخدام الموقع.
                          </p>

                          <p className="mb-4">
                            وعليه، فإنك تُقِر وتعترف وتضمن (عند استخدام موقع
                            الإمارات لتنفيذ الأحكام) الموافقة على هذه الشروط
                            والأحكام العامة.
                          </p>

                          <p className="mb-4">
                            ويمكنك التسجيل لإنشاء حساب على موقع الإمارات لتنفيذ
                            الأحكام من خلال استكمال نموذج التسجيل وإرساله إلى
                            موقع الإمارات لتنفيذ الأحكام.
                          </p>

                          <p>
                            كما تُقِر وتضمن أن جميع المعلومات المقدمة في نموذج
                            التسجيل كاملة ودقيقة وتمثلك قانونياً وأن الموقع
                            الخاص بنا غير مسؤول عن تقديم أي معلومات خاطئة وغير
                            صحيحة تم ذكرها في نموذج التسجيل ولا يحق للعملاء بأي
                            حال من الأحوال مسائلة الموقع قانونياً عن أي معلومات
                            غير صحيحة ويتحمل الأشخاص المستفيدين من الموقع
                            المسؤولية القانونية والتعاقدية في حال ثبت أن
                            المعلومات المقدمة غير صحيحة.
                          </p>

                          <p>
                            إذا قُمت بالتسجيل لفتح حساب على موقع الإمارات لتنفيذ
                            الأحكام، عليك أن تقوم بإختيار إسم المستخدم وكلمة
                            المرور الخاصة بك، وسوف يتم طلب عنوان البريد
                            الإلكتروني، وبلد الإقامة والعنوان بالتفصيل، ورقم
                            الهاتف، رقم الهوية، اللغة المفضلة، وقد يُطلب منك
                            تقديم معلومات الفواتير/ الدفع مثل: معلومات فواتير
                            بطاقة الائتمان، ونوع بطاقة الائتمان الخاصة بك، رقم
                            البطاقة، وتاريخ إنتهاء الصلاحية، وكذلك عنوان إرسال
                            الفواتير إذا كنت مشترك في خدمات الدفع الإلكتروني.
                          </p>

                          <p>
                            ويمكنك الإشتراك لتصلك النشرات الإخبارية الخاصة بموقع
                            الإمارات لتنفيذ الأحكام والإطلاع على أخر أخبارنا
                            وتحديثاتنا وفي هذه الحالة تُقِر وتوافق على ما يلي:
                          </p>

                          <ul className="list-disc list-inside mb-4 space-y-2">
                            <li className="!mb-[1rem]">
                              الحفاظ على سرية كلمة المرور الخاصة بك
                            </li>
                            <li className="!mb-[1rem]">
                              إخطار موقع الإمارات لتنفيذ الأحكام على الفور (من
                              خلال وسائل التواصل) في حالة الكشف عن كلمة المرور
                              الخاصة بك.
                            </li>
                            <li className="!mb-[1rem]">
                              مسئوليتك عن أي نشاط يتم على موقع الإمارات لتنفيذ
                              الأحكام ناتج عن فشلك في الحفاظ على سرية كلمة
                              المرور الخاصة بك، وأنك المسؤول عن أي خسائر قد تنشأ
                              نتيجة هذا الفشل.
                            </li>

                            <li className="!mb-[1rem]">
                              يقر المستخدم / العضو بموافقته على تعويض الإمارات
                              لتنفيذ الأحكام عن أي إستخدام غير لائق أو غير مصرح
                              به أو غير قانوني يتم على حسابه سواء من جانبه أو من
                              جانب أي شخص أخر يحصل على إمكانية الدخول إلى الحساب
                              عن طريق الموقع أو التطبيق الإلكتروني وذلك بإستخدام
                              إسم المستخدم وكلمة المرور الخاصين به.
                            </li>

                            <li className="!mb-[1rem]">
                              استخدام الحساب بمفردك، ولا يجوز نقل ذلك الحساب إلى
                              أي طرف ثالث، أو تخويل أي طرف باستخدامه، وتكون
                              مسؤولاً في حالة قِيامك بتفويض إدارة حسابك لأي طرف
                              ثالث.
                            </li>

                            <li className="!mb-[1rem]">
                              يتحمل العميل المسؤولية عن تزويد إدارة الموقع
                              بالمعلومات الصحيحة للخدمة المطلوب تنفيذها.
                            </li>

                            <li className="!mb-[1rem]">
                              حق الإمارات لتنفيذ الأحكام، وفقاً لتقديرها المطلق
                              ودون إشعار مسبق أو إبداء أسباب، في تعليق الحساب
                              الخاص بك أو إلغاؤه أو تعديل بيانات الحساب.
                            </li>

                            <li className="!mb-[1rem]">
                              - وفي حال رغبة أحد العملاء في إيقاف التعامل مع
                              موقع الإمارات لتنفيذ الأحكام فان الموقع سيقوم
                              بالإحتفاظ بجميع العمليات والنشاطات التي قام بها
                              على موقع الإمارات لتنفيذ الأحكام.
                            </li>
                          </ul>
                        </section>

                        <section>
                          <h2 className="!text-[1.5rem] md:text-xl font-semibold mb-4">
                            الأطراف المستخدمين للموقع
                          </h2>

                          <h4 className="!text-[1.5rem] md:text-xl font-semibold mb-4">
                            العملاء المستفيدين وهم:
                          </h4>
                          <ul className="list-disc list-inside mb-4">
                            <li className="!mb-[1rem]">
                              المُحامون: وهو العميل الذي يكون مُمثلاً عن أحد
                              الأطراف بالقضايا، ويلتزم بتقديم ما يثبت صفته
                              القانونية عن الطرف الممثل عنه.
                            </li>
                            <li className="!mb-[1rem]">
                              المُتعاملون: وهم العملاء المتمثلين في المتقاضين
                              الأفراد، الشركات، مراكز الخدمة، الخبراء، وأي طرف
                              له علاقة مباشرة بالإجراء، وأن يُثبتوا لموقع
                              الإمارات لتنفيذ الأحكام صفتهم القانونية عن الطرف
                              المُمثلين عنه.
                            </li>
                            <li className="!mb-[1rem]">
                              موقع الإمارات لتنفيذ الأحكام: وهو هنا يُعتبر الطرف
                              المعني بتنفيذ قرارات المحكمة.
                            </li>
                          </ul>
                        </section>

                        <section>
                          <h2 className="!text-[1.5rem] md:text-xl font-semibold mb-4">
                            شروط وأحكام عامة
                          </h2>
                          <ul className="list-disc list-inside mb-4">
                            <li className="!mb-[1rem]">
                              أي غموض في أحد اللوائح أو سوء فهم لا يجوز تفسيره
                              بصورة تؤثر سلباً على مصلحة أياً من الأطراف.
                            </li>
                            <li className="!mb-[1rem]">
                              يُقِر المستخدم / العضو ويوافق على الشروط الخاصة
                              والمُتعلقة بتقديم خدمات التنفيذ وتعتبر مكملة
                              وجزءاً لا يتجزأ من الشروط والأحكام العامة التي
                              تحكم العلاقة بين الأطراف.
                            </li>
                            <li className="!mb-[1rem]">
                              يحق لشركة الإمارات لتنفيذ الأحكام تأجيل تنفيذ
                              الإجراء في حال وجود مشكلة فنية لا يمكن السيطرة
                              عليها في الموقع أو التطبيق الإلكتروني إلى أن يتم
                              إصلاح هذا العطل الفني.
                            </li>

                            <li className="!mb-[1rem]">
                              الإمارات لتنفيذ الأحكام غير مسؤولية قانوناً عن أي
                              خلل تِقني وفني يتم أثناء إجراء عملية التنفيذ سواء
                              على الموقع الإلكتروني أو التطبيق، ولا يحق للمستخدم
                              / العضو مُطالبة الإمارات لتنفيذ الأحكام بأية
                              تعويضات على الخسارة التي تمت لوجود عُطل تِقني وفني
                              أثناء تقديم الخدمة.
                            </li>

                            <li className="!mb-[1rem]">
                              يُقِر العميل بأن قواعد وأحكام تنفيذ قرارات المحكمة
                              مُعلنه ومُبلغه إليه من قبل الإمارات لتنفيذ الأحكام
                              وأنه أطلع عليها ويعيها بشكلٍ كافٍ وأنه قد أطلع على
                              كافة الأحكام.
                            </li>

                            <li className="!mb-[1rem]">
                              تحكم اللوائح والقوانين التي تتضمنها هذه الشروط
                              والأحكام العلاقة بين جميع الأطراف ذات الصلة
                              بالموقع أو التطبيق الإلكتروني، ولا يمكن إتخاذ أياً
                              من بنودها سنداً أو دليلاً مادياً يُستخدم ضد
                              الإمارات لتنفيذ الأحكام.
                            </li>

                            <li className="!mb-[1rem]">
                              الإمارات لتنفيذ الأحكام لها كل الحق في تحديد إيقاف
                              أو إنهاء أياً من الخدمات التي يوفرها الموقع أو
                              التطبيق الإلكتروني، كما يحق لها منع المستخدمين /
                              الأعضاء من دخول الموقع أو التطبيق و/أو إزالة أي
                              مواد منشورة عليه، وإتخاذ أية إجراءات سواءً
                              إلكترونية أو قانونية تراها مناسبة وذلك لمنع
                              المستخدمين من دخول الموقع دون سابق إنذار، في حال
                              إذا وجدت "الإمارات لتنفيذ الأحكام" ضرورة إتخاذ
                              أياً من الإجراءات الواردة أعلاه لأي سبب من
                              الأسباب، علماً بأن الإمارات لتنفيذ الأحكام تمتلك
                              الحق بإلغاء الحسابات غير المُفعلة أو الحسابات غير
                              النشطة عند الحاجة لذلك.
                            </li>

                            <li className="!mb-[1rem]">
                              يحق للإمارات لتنفيذ الأحكام تغيير رسوم الخدمات
                              التي يقدمها الموقع بصورة مؤقتة أو دائمة بدون إشعار
                              مُسبق، وذلك لأسباب ترويجية أو عند طرح خدمات جديدة
                              أو لأي سبب آخر.
                            </li>

                            <li className="!mb-[1rem]">
                              جميع المعاملات فى سداد رسوم الخدمات على الموقع
                              الإلكترونى للإمارات لتنفيذ الأحكام تتم بالدرهم
                              الإماراتي، والإمارات لتنفيذ الأحكام ليست مسؤولة عن
                              أي خسارة ناتجة عن التحويل بين العملات و/أو الخسارة
                              الناتجة عن التحويلات المصرفية بين العملات المختلفة
                            </li>

                            <li className="!mb-[1rem]">
                              سيتم إضافة ضريبة القيمة المُضافة وفقاً لقانون
                              الهيئة الإتحادية للضرائب (FTA) رقم 8/2017 الصادر
                              عن حكومة الإمارات العربية المتحدة بواقع 5% على سعر
                              تقديم الخدمة (حيثما ينطبق ذلك) وجميع الرسوم،
                              وتطبيق ضريبة القيمة المُضافة على السعر النهائي
                              يُعد من إلتزامات العميل وليس إلتزاماً على الإمارات
                              لتنفيذ الأحكام وفقاً لقانون الهيئة الإتحادية
                              للضرائب.
                            </li>

                            <li className="!mb-[1rem]">
                              لن تتحمل الإمارات لتنفيذ الأحكام مسؤولية أي خلل
                              فني في النظام أو عدم إتمام عملية إتمام الطلب
                              وتقديم الخدمة أو أي صُعوبات فنية.
                            </li>

                            <li className="!mb-[1rem]">
                              في حال حدوث أي خطأ فني يُؤدي إلى استرجاع المبلغ
                              للعميل، يلتزم العميل بإعادة المبلغ المُسترد إلى
                              شركة الإمارات لتنفيذ الأحكام، وفي حال عدم
                              الإمتثال، يحق لشركة الإمارات لتنفيذ الأحكام إتخاذ
                              الإجراءات القانونية اللازمة ضد العميل.
                            </li>
                          </ul>
                        </section>
                        <section>
                          <h2 className="!text-[1.5rem] md:text-xl font-semibold mb-4">
                            التعديلات على الشروط والأحكام
                          </h2>
                          <p className="mb-4">
                            تحتفظ شركة الإمارات لتنفيذ الأحكام بحقها في تعديل
                            الشروط والأحكام في أي وقت تراه مناسباً.
                          </p>
                        </section>

                        <section>
                          <h2 className="!text-[1.5rem] md:text-xl font-semibold mb-4">
                            الإضرار بالإمارات لتنفيذ الأحكام
                          </h2>
                          <ul className="list-disc list-inside mb-4">
                            <li className="!mb-[1rem]">
                              التعهد بعدم إستخدام برامج الإختراقات أو القرصنة
                              والتى هى على سبيل المثال لا الحصر (الهكرز- الروبوت
                              - سبايدر - سكرابر ...إلخ) وذلك للدخول إلى الموقع
                              أو التطبيق الإلكتروني لأي سبب من الأسباب والإلتزام
                              بكافة أحكام القوانين المتعلقة بتقنية المعلومات
                              والتى يتضمنها المرسوم بقانون اتحادي رقم 34 لسنة
                              2021 في شأن مكافحة الشائعات والجرائم الإلكترونية.
                            </li>
                            <li className="!mb-[1rem]">
                              التعهد بعدم القيام بأي نشاط من شأنه أن يؤثر سلباً
                              على البنية التحتية للموقع، أو التطبيق الإلكتروني،
                              أو منع مستخدمي الموقع، أو التطبيق من الوصول إليه،
                              أو قصر عملية الوصول على فئة معينة بأي طريقة كانت.
                            </li>
                            <li className="!mb-[1rem]">
                              ويحق لموقع الإمارات لتنفيذ الأحكام مراجعة المحتوى
                              الخاص بك بإستمرار وبشكل دوري ومراقبة المحتوى الخاص
                              بك وإزالته في حال مخالفة شروط الموقع.
                            </li>

                            <li className="!mb-[1rem]">
                              تتعهد وتلتزم بإبلاغ موقع الإمارات لتنفيذ الأحكام
                              فور إكتشافك وجود محتوى أو نشاط غير قانوني على موقع
                              الإمارات لتنفيذ الأحكام أو يخالف هذه الشروط
                              والأحكام العامة للموقع، وذلك عن طريق التواصل مع
                              الإمارات لتنفيذ الأحكام.
                            </li>
                          </ul>
                        </section>

                        <section>
                          <h2 className="!text-[1.5rem] md:text-xl font-semibold mb-4">
                            الإنهاء
                          </h2>
                          <p className="mb-4">
                            يمتلك موقع الإمارات لتنفيذ الأحكام الحق في إنهاء
                            استفادتك من الموقع دون إشعار مُسبق في حال خالفت ولم
                            تقم بالإمتثال لأي شرط من الشروط والأحكام المقررة على
                            الموقع.
                          </p>

                          <p className="mb-4">
                            في حال قيام العميل بتدارك الخطأ الذي وقع منه وقام
                            بتصويبه والإمتثال للشروط والأحكام الخاصة بالموقع
                            خلال المدة التي يُقررها الموقع ويراها مناسبة يجوز لك
                            الإستمرار في الإستفادة من الخدمات التي يقدمها الموقع
                            وفي حال إنتهاء هذه المدة دون ان يقوم العميل بتصويب
                            الأخطاء والإمتثال للشروط والأحكام الخاصة بالموقع
                            أثناء المدة التي يمنحها الموقع للعميل يقوم الموقع
                            بتجميد عضويته وتسجيله بالموقع، ويمنع العميل من
                            الدخول إلى حسابه على الموقع والإطلاع على محتوياته
                            ولا يحق للعميل في هذه الحالة إتخاذ أي إجراءات
                            قانونية ضد الموقع أو المطالبة بالتعويض.
                          </p>
                        </section>

                        <section>
                          <h2 className="!text-[1.5rem] md:text-xl font-semibold mb-4">
                            حقوق النسخ
                          </h2>
                          <p className="mb-4">
                            محتويات هذا الموقع بما في ذلك النصوص والصور والروابط
                            هي ملك الإمارات لتنفيذ الأحكام ومحمية بموجب حقوق
                            الطبع والنشر.
                          </p>

                          <p className="mb-4">
                            تحتفظ الإمارات لتنفيذ الأحكام بجميع حقوق الطبع
                            والنشر والعلامات التِجارية وبراءات الإختراع وحقوق
                            الملكية الفكرية وغيرها من المعلومات الواردة في هذا
                            الموقع.
                          </p>

                          <p className="mb-4">
                            يُمنع منعاً باتاً أي إستخدام غير مُصرح به،
                            والإستنساخ أو طباعة المعلومات والمواد وحقوق الملكية
                            الواردة في هذا الموقع.
                          </p>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div> */}
          <div className="flex flex-col items-center justify-center my-5">
            <h3 className="text-3xl lg:text-4xl text-gray-600 leading-snug font-semibold">
              {lang === "en"
                ? enPhrase["lbl_terms_condtions"]
                : arPhrase["lbl_terms_condtions"]}
            </h3>
          </div>
          <div
            className="text-gray-600 leading-relaxed"
            dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
          ></div>
        </Container>
      </section>
      <FooterLayout
        lang={lang}
        enPhrase={enPhrase}
        arPhrase={arPhrase}
        dashboard
      />
    </div>
  );
};

export default TermsAndConditions;
