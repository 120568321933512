import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsUpload } from 'react-icons/bs';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import AR from 'react-phone-number-input/locale/ar';
import EN from 'react-phone-number-input/locale/en';
import 'react-phone-number-input/style.css';
import { Loader, toast, toastHtml } from '../components/utils';
import { PhrasesContext } from '../context/phrasesContext';
import ThemeLayout from '../layouts/ThemeLayout';
import {
  UploadReceipt,
  UploadReceiptBase64,
  getInfo,
} from '../services/Common';
const UploadRecepit = () => {
  const maxOffset = 60;

  const inputFile = useRef(null);
  const [lang, setLang] = useState("");
  // const [enPhrase, setEnPhrase] = useState({});
  // const [arPhrase, setArPhrase] = useState({});
  const [courts, setCourts] = useState([]);
  const [caseTypes, setCaseTypes] = useState([]);
  const [years, setYears] = useState([]);
  const [file, setFile] = useState('');
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();

  const [enPhrase, arPhrase] = useContext(PhrasesContext);

  

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    const year = new Date().getFullYear();
    setYears(Array.from(new Array(maxOffset), (val, index) => year - index));

    const lang = window.localStorage.getItem('lang');

    if (lang === 'en') {
      document.body.dir = 'ltr';
      setLang('en');
    } else {
      document.body.dir = 'rtl';
      setLang('ar');
    }

    // getPhrasesService();
    getInfoService();
  }, []);

  // const changeLanguage2 = (lang) => {
  //   if (lang === 'en') {
  //     document.body.dir = 'rtl';
  //     setLang('ar');
  //     window.localStorage.setItem('lang', 'ar');
  //   } else {
  //     document.body.dir = 'ltr';
  //     setLang('en');
  //     window.localStorage.setItem('lang', 'en');
  //   }
  //   window.location.reload();
  // };

  // const getPhrasesService = () => {
  //   return getPhrases().then((res) => {
  //     if (res.status === 200) {
  //       const en_api = {};
  //       const ar_api = {};
  //       res.data.map((phrase) => {
  //         en_api[phrase.phraseKey] = phrase.phraseEn;
  //         ar_api[phrase.phraseKey] = phrase.phraseAr;
  //         return "";
  //       });
  //       setEnPhrase(en_api);
  //       setArPhrase(ar_api);
  //     }
  //   });
  // };

  const getInfoService = () => {
    return getInfo().then((res) => {
      if (res.status === 200) {
        setCourts((courts) => res.data.courts);
        setCaseTypes((caseTypes) => res.data.caseTypes);
      }
    });
  };

  const updateData = (data) => {
    
    UploadReceipt(data).then(
      (res) => {
        console.log(res);

        setLoading(false);
        const title =
          lang === 'en'
            ? enPhrase['lbl_Success_upload']
            : arPhrase['lbl_Success_upload'];
        const success = `<div className=" flex flex-col items-center">
        <span>${
          lang === 'en'
            ? enPhrase['lbl_success_upload_receipt']
            : arPhrase['lbl_success_upload_receipt']
        }</span><br/><p className="text-black bg-zinc-200 w-fit text-center rounded-full font-medium  px-4 py-3">${
          lang === 'en' ? enPhrase['lbl_ref'] : arPhrase['lbl_ref']
        } #${res}</p></div>`;

        const ok =
          lang === 'en' ? enPhrase['lbl_Complete'] : arPhrase['lbl_Complete'];
        toastHtml(title, success, ok);

        document.getElementById('formreceipt').reset();
        setPhoneNumber('');
        document.getElementsByClassName('PhoneInputInput')[0].value = null;
      },
      (err) => {
        const title =
          lang === 'en' ? enPhrase['lbl_Error'] : arPhrase['lbl_Error'];
        const success =
          lang === 'en' ? enPhrase['lbl_Error_Msg'] : arPhrase['lbl_Error_Msg'];
        const ok = lang === 'en' ? enPhrase['lbl_Ok'] : arPhrase['lbl_Ok'];
        toast(title, success, ok, 'error');
        console.log(err);
      }
    );
  };
  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const onSubmit = async (data) => {
    setLoading(true);
    let recepitInfo = {
      mobile: data.mobilenumber,
      courtId: +data.court,
      caseNumber: +data.caseNumber,
      caseYear: +data.caseYear,
      caseTypeId: +data.caseType,
      receiptHash: 'string',
    };
    const imageD = file.replace(/^data:image\/[a-z]+;base64,/, '');

    UploadReceiptBase64(imageD).then(
      async (response) => {
        const res = await response.text();
        if (res.error) {
          const title =
            lang === 'en' ? enPhrase['lbl_Error'] : arPhrase['lbl_Error'];
          const success =
            lang === 'en'
              ? enPhrase['lbl_Upload_Error_Msg']
              : arPhrase['lbl_Upload_Error_Msg'];
          const ok = lang === 'en' ? enPhrase['lbl_Ok'] : arPhrase['lbl_Ok'];
          toast(title, success, ok, 'error');
        } else {
          setFile(null);
          recepitInfo.receiptHash = res;
          updateData(recepitInfo);
        }
      },
      (err) => {
        setLoading(false);
        const title =
          lang === 'en' ? enPhrase['lbl_Error'] : arPhrase['lbl_Error'];
        const success =
          lang === 'en'
            ? enPhrase['lbl_Upload_Error_Msg']
            : arPhrase['lbl_Upload_Error_Msg'];
        const ok = lang === 'en' ? enPhrase['lbl_Ok'] : arPhrase['lbl_Ok'];
        toast(title, success, ok, 'error');
      }
    );
  };

  return (
    <ThemeLayout>
      <div className='w-full max-w-[1140px]'>
        <div className='flex flex-col gap-10 items-center justify-center py-16'>
          <div className='items-center w-full bg-white'>
            <div className='p-10 shadow-2xl w-full  bg-white'>
              <div className=''>
                <div>
                  <h4 className='text-xl text-black font-medium py-3 leading-8'>
                    {lang === 'en'
                      ? enPhrase['lbl_upload_receipt']
                      : arPhrase['lbl_upload_receipt']}
                  </h4>
                  <p className='text-sm text-gray-400 font-normal'>
                    {lang === 'en'
                      ? enPhrase['lbl_upload_receipt_desc']
                      : arPhrase['lbl_upload_receipt_desc']}
                  </p>
                </div>
                <div className=' '>
                  <form id='formreceipt' onSubmit={handleSubmit(onSubmit)}>
                    <div className=''>
                      <div className='space-y-6 bg-white  py-5 '>
                        <div className='grid grid-cols-6 gap-6'>
                          <div className='col-span-6 sm:col-span-3'>
                            <label
                              htmlFor='mobilenumber'
                              className='block text-sm font-medium text-gray-700'>
                              {lang === 'en'
                                ? enPhrase['lbl_Mobile_Number']
                                : arPhrase['lbl_Mobile_Number']}
                            </label>
                            <PhoneInput
                              name='mobilenumber'
                              defaultCountry='AE'
                              style={{ direction: 'ltr' }}
                              international
                              countryCallingCodeEditable={true}
                              labels={lang === 'en' ? EN : AR}
                              value={phoneNumber}
                              onFocus={() => {
                                setFocused(true);
                              }}
                              onBlur={() => {
                                setFocused(false);
                              }}
                              className={` mt-1  focus:border-gold-100 px-2 w-full sm:text-sm py-0 shadow-sm  rounded-md border    ${
                                focused
                                  ? 'border-gold-100 '
                                  : errors.mobilenumber
                                  ? ' border-red-600'
                                  : 'border-gray-300'
                              } `}
                              control={{
                                ...register('mobilenumber', {
                                  required: true,
                                }),
                              }}
                              onChange={(e) => {
                                if (e && isValidPhoneNumber(e)) {
                                  delete errors.mobilenumber;
                                  setValue('mobilenumber', e);
                                } else {
                                  setValue('mobilenumber', '');
                                }
                              }}
                              rules={{ required: true }}
                            />

                            {errors.mobilenumber && (
                              <span role='alert' className=' text-xs'>
                                {lang === 'en'
                                  ? enPhrase['lbl_error_mobile_number']
                                  : arPhrase['lbl_error_mobile_number']}
                              </span>
                            )}
                          </div>

                          <div className='col-span-6 sm:col-span-3'>
                            <label
                              htmlFor='court'
                              className='block text-sm font-medium text-gray-700'>
                              {lang === 'en'
                                ? enPhrase['lbl_Court']
                                : arPhrase['lbl_Court']}
                            </label>
                            <select
                              id='court'
                              name='court'
                              placeholder={
                                lang === 'en'
                                  ? enPhrase['lbl_Court']
                                  : arPhrase['lbl_Court']
                              }
                              autoComplete='court'
                              {...register('court', {
                                required: true,
                              })}
                              className={`mt-1 block w-full rounded-md border    bg-white sm:text-sm py-2 px-3 shadow-sm  focus:border-gold-100 focus:ring-gold-100  ${
                                lang === 'en' ? ' bg-right' : ' bg-left'
                              } ${
                                errors.court
                                  ? ' border-red-600'
                                  : ' border-gray-300'
                              }`}>
                              <option value='' className='text-slate-200'>
                                {lang === 'en'
                                  ? enPhrase['lbl_Court']
                                  : arPhrase['lbl_Court']}
                              </option>

                              {courts.map((court) => (
                                <option key={court.id} value={court.id}>
                                  {lang === 'en'
                                    ? court['courtName']
                                    : court['courtNameAr']}
                                </option>
                              ))}
                            </select>
                            {errors.court && (
                              <span role='alert' className=' text-xs'>
                                {lang === 'en'
                                  ? enPhrase['drpdwn_Court_error']
                                  : arPhrase['drpdwn_Court_error']}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className='grid grid-cols-6 gap-6'>
                          <div className='col-span-6 sm:col-span-2'>
                            <label
                              htmlFor='caseNumber'
                              className='block text-sm font-medium text-gray-700'>
                              {lang === 'en'
                                ? enPhrase['lbl_Case_Number']
                                : arPhrase['lbl_Case_Number']}
                            </label>
                            <input
                              type='number'
                              name='caseNumber'
                              key='caseNumber'
                              placeholder={
                                lang === 'en'
                                  ? enPhrase['lbl_Case_Number']
                                  : arPhrase['lbl_Case_Number']
                              }
                              aria-invalid={
                                errors.caseNumber ? 'true' : 'false'
                              }
                              {...register('caseNumber', {
                                required: true,
                              })}
                              className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                                errors.caseNumber
                                  ? ' border-red-600'
                                  : ' border-gray-300'
                              }`}
                            />
                            {errors.caseNumber && (
                              <span role='alert' className=' text-xs'>
                                {lang === 'en'
                                  ? enPhrase['lbl_Case_Number_Error']
                                  : arPhrase['lbl_Case_Number_Error']}
                              </span>
                            )}
                          </div>
                          <div className='col-span-6 sm:col-span-2'>
                            <label
                              htmlFor='caseYear'
                              className='block text-sm font-medium text-gray-700'>
                              {lang === 'en'
                                ? enPhrase['lbl_Case_year']
                                : arPhrase['lbl_Case_year']}
                            </label>
                            <select
                              id='caseYear'
                              name='caseYear'
                              placeholder={
                                lang === 'en'
                                  ? enPhrase['lbl_Case_year']
                                  : arPhrase['lbl_Case_year']
                              }
                              autoComplete='caseYear'
                              {...register('caseYear', {
                                required: true,
                              })}
                              className={`mt-1 block w-full rounded-md border    bg-white py-2 px-3 shadow-sm  focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                                lang === 'en' ? ' bg-right' : ' bg-left'
                              } ${
                                errors.caseYear
                                  ? ' border-red-600'
                                  : ' border-gray-300'
                              }`}>
                              <option value='' className='text-slate-200'>
                                {lang === 'en'
                                  ? enPhrase['lbl_Case_year']
                                  : arPhrase['lbl_Case_year']}
                              </option>
                              {years.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>

                            {errors.caseYear && (
                              <span role='alert' className=' text-xs'>
                                {lang === 'en'
                                  ? enPhrase['lbl_Error_Case_Year']
                                  : arPhrase['lbl_Error_Case_Year']}
                              </span>
                            )}
                          </div>
                          <div className='col-span-6 sm:col-span-2'>
                            <label
                              htmlFor='caseType'
                              className='block text-sm font-medium text-gray-700'>
                              {lang === 'en'
                                ? enPhrase['lbl_Case_Type']
                                : arPhrase['lbl_Case_Type']}
                            </label>
                            <select
                              id='caseType'
                              name='caseType'
                              placeholder={
                                lang === 'en'
                                  ? enPhrase['lbl_Case_Type']
                                  : arPhrase['lbl_Case_Type']
                              }
                              autoComplete='caseType'
                              {...register('caseType', {
                                required: true,
                              })}
                              className={`mt-1 block w-full rounded-md border    bg-white py-2 px-3 shadow-sm  focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                                lang === 'en' ? ' bg-right' : ' bg-left'
                              } ${
                                errors.caseType
                                  ? ' border-red-600'
                                  : ' border-gray-300'
                              }`}>
                              <option value='' className='text-slate-200'>
                                {lang === 'en'
                                  ? enPhrase['lbl_Error_Case_Type']
                                  : arPhrase['lbl_Error_Case_Type']}
                              </option>
                              {caseTypes.map((caseType) => (
                                <option key={caseType.id} value={caseType.id}>
                                  {lang === 'en'
                                    ? caseType['caseTypeName']
                                    : caseType['caseTypeName']}
                                </option>
                              ))}
                            </select>

                            {errors.caseType && (
                              <span role='alert' className=' text-xs'>
                                {lang === 'en'
                                  ? enPhrase['lbl_Error_Case_Type']
                                  : arPhrase['lbl_Error_Case_Type']}
                              </span>
                            )}
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor='caseType'
                            className='block text-sm font-medium text-gray-700'>
                            {lang === 'en'
                              ? enPhrase['lbl_upload_receipt']
                              : arPhrase['lbl_upload_receipt']}
                          </label>
                          <div className='mt-1 cursor-pointer flex '>
                            <label
                              htmlFor='fileupload'
                              className='relative cursor-pointer flex w-[100%] items-center justify-center w-100   border-2 border-dashed bg-gold-10 border-gray-300 r rounded-md  px-6 pt-5 pb-6 font-medium text-gold-100 focus-within:outline-none focus-within:ring-2  '>
                              {!file && <BsUpload size={20} className='mx-4' />}
                              {!file && (
                                <p className='text-xs text-gray-500'>
                                  {lang === 'en'
                                    ? enPhrase['lbl_upload_receipt']
                                    : arPhrase['lbl_upload_receipt']}
                                </p>
                              )}
                              <input
                                id='fileupload'
                                name='fileupload'
                                ref={inputFile}
                                type='file'
                                accept='image/png, image/gif, image/jpeg'
                                {...register('fileupload', {
                                  required: true,
                                })}
                                onChange={(e) => {
                                  if (e.target.files.length > 0) {
                                    getBase64(e.target.files[0], (res) => {
                                      setFile(res);
                                    });
                                  } else {
                                    setFile(null);
                                  }
                                }}
                                className='sr-only '
                              />

                              {file && (
                                <span role='alert' className=' text-xs'>
                                  <img
                                    src={file}
                                    alt='receipt'
                                    style={{ maxHeight: '10vh' }}
                                  />
                                </span>
                              )}
                              {!file && errors.fileupload && (
                                <span
                                  role='alert'
                                  className='text-red-700 mx-3 text-xs'>
                                  {lang === 'en'
                                    ? enPhrase['lbl_error_attach']
                                    : arPhrase['lbl_error_attach']}
                                </span>
                              )}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='py-3'>
                        <button
                          type='submit'
                          disabled={loading}
                          className='inline-flex justify-center gap-2 text-xs items-center rounded-md border border-transparent bg-gold-100 py-4 px-8 text-md font-medium text-white shadow-sm   focus:outline-none focus:ring-2 focus:ring-offset-2'>
                          <span>
                            {lang === 'en'
                              ? enPhrase['lbl_upload_receipt']
                              : arPhrase['lbl_upload_receipt']}
                          </span>
                          {loading && <Loader color={'white'} />}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeLayout>
  );
};

export default UploadRecepit;
