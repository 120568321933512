import React, { useContext, useEffect, useState } from "react";
import { Container } from "../components/utils";
import { PhrasesContext } from "../context/phrasesContext";
import ThemeLayout from "../layouts/ThemeLayout";
import { getRefundPolicy } from "../services/Common";
const Refund = () => {
  const [lang, setLang] = useState("");
  const [refund, setRefund] = useState("");
  const [enPhrase, arPhrase] = useContext(PhrasesContext);

  // const getRefundPolices = (lang) => {
  //   console.log("Language sent to API:", lang);

  //   return getRefundPolicy(lang).then((res) => {
  //     if (!res || res.length === 0) {
  //       console.error("Empty or invalid response received");
  //     } else {
  //       console.log("API Response Data:", res);
  //       setRefund(res);
  //     }
  //   });
  // };
  const getRefundPolices = (currentLang) => {
    return getRefundPolicy(currentLang).then((res) => {
      setRefund(res);
    });
  };

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }
    getRefundPolices(currentLang);
  }, []);

  const sanitizedHtml = refund.replace(/className/g, "class");

  return (
    <ThemeLayout>
      <section
        id="faq"
        className="w-full overflow-hidden bg-gold-10 border-t  wow fadeInUp min-h-[678px] xl:pt-[0rem]"
      >
        <Container>
          {/* <div className="flex flex-col  items-center justify-center pb-16 text-justify">
            <div className="flex flex-col items-center justify-center my-5">
              <h3 className="text-3xl lg:text-4xl text-black leading-snug font-semibold">
                {lang === "en"
                  ? enPhrase["lbl_Refund_Policy"]
                  : arPhrase["lbl_Refund_Policy"]}
              </h3>
            </div>
            <div className="items-center w-full ">
              {lang === "en" && (
                <div className="w-full p-10 terms">
                  <section>
                    <ul className="list-disc list-inside mb-4 space-y-2">
                      <li className="!mb-[1rem]">
                        The service applicant has the right to refund the
                        service fee if it has not been implemented or initiated.
                      </li>
                      <li className="!mb-[1rem]">
                        The refund will be credited to the same bank account
                        used for the transfer, via a cheque issued by the
                        company.
                      </li>
                      <li className="!mb-[1rem]">
                        The refund will be credited to the same card used for
                        the transaction.
                      </li>

                      <li className="!mb-[1rem]">
                        The amount for direct deposit transactions will be
                        refunded by issuing a cheque to the depositor.
                      </li>
                    </ul>
                  </section>
                </div>
              )}

              {lang === "ar" && (
                <div className="w-full p-10 terms">
                  <section>
                    <ul className="list-disc list-inside mb-4 space-y-2">
                      <li className="!mb-[1rem]">
                        يحق لطالب الخدمة استرجاع رسم الخدمة إذا لم يتم تنفيذها
                        أو الشروع في تنفيذها.
                      </li>
                      <li className="!mb-[1rem]">
                        يتم استرجاع المبلغ على ذات الحساب البنكي الذي تم التحويل
                        منه بشيك صادر من الشركة.
                      </li>
                      <li className="!mb-[1rem]">
                        يتم استرجاع المبلغ على ذات الكارت الذي تم استخدامه في
                        سداد الرسم في حالة الدفع بالماكينة.
                      </li>

                      <li className="!mb-[1rem]">
                        يتم استرجاع المبلغ لمعاملات الإيداع المباشر في الحساب عن
                        طريق إصدار شيك للمُودع.
                      </li>
                    </ul>
                  </section>
                </div>
              )}
            </div>
          </div> */}
          <div className="flex flex-col items-center justify-center my-5">
            <h3 className="text-2xl lg:text-3xl text-gray-600 leading-snug font-semibold">
              {lang === "en"
                ? enPhrase["lbl_Refund_Policy"]
                : arPhrase["lbl_Refund_Policy"]}
            </h3>
          </div>
          <div
            className="text-gray-600 leading-relaxed"
            dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
          ></div>
        </Container>
      </section>
    </ThemeLayout>
  );
};

export default Refund;
