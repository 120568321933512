import React, { createContext, useContext, useEffect, useState } from "react";
import ContactusModel from "../components/ContactusModel";
import LevelHappinessLatest from "../components/happiness/LevelHappinessLatest";
import PopWrapper from "../components/happiness/PopWrapper";
import SharedModal from "../components/happiness/SharedModal";
import SuccessHappinessMessage from "../components/happiness/SuccessHappinessMessage";
import Survey from "../components/happiness/Survey";
import { getSurvey } from "../services/Common";
// import { useMemo } from "react";

const AuthContext = createContext();

export function AuthWrapper({ children }) {
  const [happinessShowModal, setHappinessShowModal] = useState(false);
  const [surveyShowModal, setSurveyShowModal] = useState(false);
  const [happinessMeter, setHappinessMeter] = useState(null);
  const [surveyDetails, setSurveyDetails] = useState([]);
  const [answersList, setAnswersList] = useState([]);
  const [contactUsShowModel, setContactUsShowModel] = useState(false)
  const [happinessSuccessMessage, setHappinessSuccessMessage] = useState(false);
  const [lang, setLang] = useState("ar");


  const body = document.querySelector("body");

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }


    // getPhrasesService();
    let token = localStorage?.getItem("access_token")
    if (token) {
      getSurveyService(currentLang);
    }
  }, []);


  const getSurveyService = (currentLang,categoryId=1) => {
    getSurvey(currentLang,categoryId).then((res) => {
      setSurveyDetails(res?.surveyDetails);
      setAnswersList(res?.answers);
    });
  };

  



  useEffect(() => {
    if (happinessShowModal) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflowY = "auto";
      body.style.overflowX = "hidden";
    }
  }, [happinessShowModal]);

  useEffect(() => {
    if (contactUsShowModel) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflowY = "auto";
      body.style.overflowX = "hidden";
    }
  }, [contactUsShowModel]);

  useEffect(() => {
    if (surveyShowModal) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflowY = "auto";
      body.style.overflowX = "hidden";
      setHappinessMeter(null);
    }
  }, [surveyShowModal]);

  const HappinessHandlerClick = () => {
    setHappinessShowModal(true);
  };

  const ContactUsHandler = () => {
    setContactUsShowModel(true)
  }

  // let sharedState = useMemo(() => {
  //   let token = localStorage?.getItem("access_token")
  //   if (!token) {
  //     localStorage.setItem('access_token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxNDRjMmRkZC1mMjQ0LTQyOGQtYmVhZS05NmIyN2IyMDM5ODgiLCJtb2JpbGUiOiIrMjAxMDEwNDg4MjkzIiwidXNlcklkIjoiMTEiLCJleHAiOjE3Mzg4NDE1ODcsImlzcyI6IkVKRSIsImF1ZCI6IkVKRSJ9.QR7mMUhFII-Cv6XmPXt6H1W1MCnAILiV1O1ACzyonT4')
  //     token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxNDRjMmRkZC1mMjQ0LTQyOGQtYmVhZS05NmIyN2IyMDM5ODgiLCJtb2JpbGUiOiIrMjAxMDEwNDg4MjkzIiwidXNlcklkIjoiMTEiLCJleHAiOjE3Mzg4NDE1ODcsImlzcyI6IkVKRSIsImF1ZCI6IkVKRSJ9.QR7mMUhFII-Cv6XmPXt6H1W1MCnAILiV1O1ACzyonT4'
  //   }
  //   return {

  //     isAuthorized: token ? true : false,
  //     token: token,
  //     HappinessHandlerClick: HappinessHandlerClick,
  //     ContactUsHandler: ContactUsHandler
  //   };
  // }, [])


  let sharedState = {
    isAuthorized: localStorage?.getItem("access_token") ? true : false,
    token: localStorage?.getItem("access_token"),
    HappinessHandlerClick: HappinessHandlerClick,
    ContactUsHandler: ContactUsHandler
  };



  return (
    <AuthContext.Provider value={sharedState}>
      {happinessShowModal ? (
        <SharedModal
          isHappiness
          openModal={happinessShowModal}
          setOpenModal={setHappinessShowModal}
        >

          <PopWrapper>
            {/* <LevelHappiness
              setHappinessMeter={setHappinessMeter}
              setOpenModal={setHappinessShowModal}
              setSurveyShowModal={setSurveyShowModal}
              getSurveyService={getSurveyService}
            /> */}
            <LevelHappinessLatest
              setHappinessMeter={setHappinessMeter}
              setOpenModal={setHappinessShowModal}
              setSurveyShowModal={setSurveyShowModal}
              getSurveyService={getSurveyService}
              answersList={answersList}
              surveyDetails={surveyDetails}
              setHappinessSuccessMessage={setHappinessSuccessMessage}
            />
          </PopWrapper>
        </SharedModal>
      ) : null}

      {happinessSuccessMessage ? (<SharedModal isHappiness openModal={happinessSuccessMessage}
        setOpenModal={setHappinessSuccessMessage} >
        <SuccessHappinessMessage
          setHappinessSuccessMessage={setHappinessSuccessMessage}
        />
      </SharedModal>) : null}

      {surveyShowModal ? (
        <SharedModal
          openModal={surveyShowModal}
          setOpenModal={setSurveyShowModal}
        >
          <PopWrapper>
            <Survey
              happinessMeter={happinessMeter}
              setOpenModal={setHappinessShowModal}
              setSurveyShowModal={setSurveyShowModal}
              setHappinessMeter={setHappinessMeter}
              surveyDetails={surveyDetails}
              answersList={answersList}
            />
          </PopWrapper>
        </SharedModal>
      ) : null}

      {contactUsShowModel ? (
        <SharedModal
          isContactUS
          contactUsShowModel={contactUsShowModel}
          openModal={contactUsShowModel}
          setOpenModal={setContactUsShowModel}
        >
          <ContactusModel setOpenModal={setContactUsShowModel} />

        </SharedModal>) : null}



      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
